import { useState } from "react";

import waveDrawLoop from '../Wave/lib/waveDrawLoop.js';
import WaveCanvas from '../Wave/WaveCanvas.jsx';
import '../Wave/Wave.css';

import { Slider, Col, Row, Button} from "antd";
import 'antd/dist/reset.css'; 

const CustomWave = ({ canvasWidth = '60%' }) => {
    const [waveIsPaused, setWaveIsPaused] = useState(false);
    const [pos, setPos] = useState(1500);

    const handlePauseWave = (e) => setWaveIsPaused(!waveIsPaused)

    const handleOnChangeSlider = (value) => {
        setPos(value)
    };

    return (
        <>        
            <div className="wlMainParent">
                <br/><br/>
                <Row>
                    <Col span={24}>
                        <div className="canvasandslider" style={{ width: canvasWidth }}>
                            <WaveCanvas
                                className="waveCanvas"
                                draw={waveDrawLoop}
                                isPaused={waveIsPaused}
                                strokeStyle="#C8A1E0"
                            />
                            <Slider
                                className="triangle-slider"
                                min={0}
                                max={4096}
                                value={pos}
                                onChange={handleOnChangeSlider}
                                defaultValue={1500}
                                style={{ margin: 0}}
                                tooltip={{open: false}}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <span className="label">Frequency Value at Current Position: {pos} Hz</span>
                  </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <span className="label">Waveform Play Control: </span>
                        <Button
                            onClick={ handlePauseWave }
                            style={{ marginTop: '10px', marginLeft: '10px'}}
                            type="primary"
                        >
                            {(waveIsPaused) ? "Play" : "Pause"}
                        </Button>
                    </Col>   
                </Row>
            </div>
        </>
    )
}

export default CustomWave;