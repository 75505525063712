// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav {
  padding: 10px;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.link {
  margin: 0 10px;
  text-decoration: none;
  color: #4E78C3;
  transition: color 0.3s ease;
}

.link:hover {
  color: #61dafb;
  text-decoration: underline;
}

.separator {
  color: white;
}

.link.active {
  font-weight: bold;
  color: #6A9AFB;
  cursor: default;
  pointer-events: none;
  text-decoration: none;
}

.link.disabled {
  font-weight: normal;
  color: #aaa;
  cursor: default;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/gavt/ResonanceMenu.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,cAAc;EACd,2BAA2B;AAC7B;;AAEA;EACE,cAAc;EACd,0BAA0B;AAC5B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,eAAe;EACf,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,eAAe;EACf,oBAAoB;AACtB","sourcesContent":[".nav {\n  padding: 10px;\n  display: flex;\n  align-items: center;\n  z-index: 1000;\n}\n\n.link {\n  margin: 0 10px;\n  text-decoration: none;\n  color: #4E78C3;\n  transition: color 0.3s ease;\n}\n\n.link:hover {\n  color: #61dafb;\n  text-decoration: underline;\n}\n\n.separator {\n  color: white;\n}\n\n.link.active {\n  font-weight: bold;\n  color: #6A9AFB;\n  cursor: default;\n  pointer-events: none;\n  text-decoration: none;\n}\n\n.link.disabled {\n  font-weight: normal;\n  color: #aaa;\n  cursor: default;\n  pointer-events: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
