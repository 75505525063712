import { useState } from 'react';

import { Alert, Space, Button, Modal, Table} from 'antd'
import ResonanceMenu from '../gavt/ResonanceMenu';
import transcripts from '../gavt/transcripts';

import '../gavt/ResonanceMenu.css';
import '../gavt/Gavt.css'

const Gavt: React.FC = () => {
  const [isTranscriptVisible, setIsTranscriptVisible] = useState(false);
  const [transcriptContent, setTranscriptContent] = useState('');

  const showTranscript = (part: keyof typeof transcripts) => {
    setTranscriptContent(transcripts[part]);
    setIsTranscriptVisible(true);
  };

  const handleTranscriptCancel = () => {
    setIsTranscriptVisible(false);
  };

  return(
    <>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <h2>Resonance: Tutorial</h2>
          <Space direction="vertical" style={{ width: '50%', marginBottom: '20px'}}>
            <Alert
              style={{ 
                backgroundColor: '#FFEFEF',
                borderColor: '#FFEFEF'
              }} 
              description={
                <>
                  <div>
                    Welcome! Here you can use a real-time display of your speech to work on resonance. NOTE: Please use the Chrome browser to access this module. Other browsers are not supported at this time.
                    <br/>
                    <br/>
                    Resonance can be challenging to understand! We encourage you to watch at least the first three tutorial sections (video clips 1-3 minutes long) before you try out the software. 
                    <br/>
                    <br/>
                    Our software works best with an external microphone plugged into your device, even if it's just a set of earbuds with an in-line microphone.
                  </div>
                </>
              }
              type="info"
            />
          
            <Table
            bordered
            pagination={false}
            showHeader={false}
            dataSource={[
              { key: '1', part1: 'Tutorial: Getting Started', part2: 'Tutorial: Next Steps' },
              { key: '2', 
                part1:
                  <div style={{ display:'inline-flex', alignItems: 'center' }}> 
                    <a href="https://youtu.be/WTs2QcTTpHo" target="_blank" rel="noreferrer">
                      Part 1. What is resonance? (1:30)
                    </a>
                    <Button type="link" onClick={() => showTranscript('part1')}>
                      Transcript
                    </Button>
                </div>,
                part2:
                  <div style={{ display:'inline-flex', alignItems: 'center' }}>
                    <a href="https://youtu.be/cpn56ZHfRv8" target="_blank" rel="noreferrer">
                      Part 4. Strategies for resonance (2:00)
                    </a>
                    <Button type="link" onClick={() => showTranscript('part4')}>
                    Transcript
                  </Button>
                </div>                
              },
              { key: '3', 
                part1:
                  <div style={{ display:'inline-flex', alignItems: 'center' }}> 
                    <a href="https://youtu.be/mFJajI69MIU" target="_blank" rel="noreferrer">
                      Part 2. Bright and dark resonance (1:15)
                    </a>
                    <Button type="link" onClick={() => showTranscript('part2')}>
                      Transcript
                    </Button>
                </div>,
                part2:
                  <div style={{ display:'inline-flex', alignItems: 'center' }}>
                    <a href="https://youtu.be/O3m2-D61POU" target="_blank" rel="noreferrer">
                      Part 5. Resonance chains (2:00)
                    </a>
                    <Button type="link" onClick={() => showTranscript('part5')}>
                      Transcript
                  </Button>
                </div>                
              },
              { key: '4', 
                part1:
                  <div style={{ display:'inline-flex', alignItems: 'center' }}> 
                    <a href="https://www.youtube.com/watch?v=wPpiuMfIb3E" target="_blank" rel="noreferrer">
                      Part 3. Modifying resonance (1:20)
                    </a>
                    <Button type="link" onClick={() => showTranscript('part3')}>
                      Transcript
                    </Button>
                </div>,
                part2:
                  <div style={{ display:'inline-flex', alignItems: 'center' }}>
                    <a href="https://youtu.be/PslG19nE0Yw" target="_blank" rel="noreferrer">
                      Part 6. Pitch versus resonance (2:50)
                    </a>
                    <Button type="link" onClick={() => showTranscript('part6')}>
                      Transcript
                  </Button>
                </div>                
              },
            ]}
            columns={[
              {
                dataIndex: 'part1',
                key: 'part1',
                render: (text, record, index) => {
                  if (index === 0) {
                    return {
                      children: text,
                      props: {
                        colSpan: 1,
                      },
                    };
                  }
                  return text;
                },
              },
              {
                dataIndex: 'part2',
                key: 'part2',
                render: (text, record, index) => {
                  if (index === 0) {
                    return {
                      children: text,
                      props: {
                        colSpan: 1,
                      },
                    };
                  }
                  return text;
                },
              },
            ]}
          />

          <Modal
            title="Transcript"
            open={isTranscriptVisible}
            onCancel={handleTranscriptCancel}
            footer={[
              <Button key="close" onClick={handleTranscriptCancel}>
                Close
              </Button>,
            ]}
          >
            <div dangerouslySetInnerHTML={{ __html: transcriptContent }} />
          </Modal>
        </Space>
      </div>
    <ResonanceMenu />
    </>

  )
}
export default Gavt;