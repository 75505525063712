import { FormValues } from './GavtSelection';

import { Form, Checkbox, Radio, Button, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

// const vowels = ['/i/', '/ɪ/', '/ɛ/', '/æ/', '/ɑ/', '/ɔ/', '/ʌ/', '/u/', '/ʊ/'];
// const vowels = ['ee', 'ih', 'eh', 'ae', 'ah', 'aw', 'uh', 'ooh', 'oo'];
const vowels = [
  { label: <span>s<strong>ee</strong>d</span>, value: 'ee' },
  { label: <span>h<strong>i</strong>d</span>, value: 'ih' },
  { label: <span>h<strong>ea</strong>d</span>, value: 'eh' },
  { label: <span>s<strong>a</strong>d</span>, value: 'ae' },
  { label: <span>b<strong>u</strong>d</span>, value: 'uh' },
  { label: <span>g<strong>oo</strong>d</span>, value: 'oo' },
  { label: <span>g<strong>o</strong>d</span>, value: 'ah' },
  { label: <span>l<strong>aw</strong>n</span>, value: 'aw' },
  { label: <span>sp<strong>oo</strong>n</span>, value: 'ooh' },
  
];
// const regions = ['Mid_Atlantic', 'New_England', 'North', 'Midland', 'South', 'West'];

interface GavtFormProps {
  onSubmit: (values: FormValues) => void;
  onFormChange: (changedValues: Partial<FormValues>, allValues: FormValues) => void; // 新增 onFormChange 回调
}

const GavtForm: React.FC<GavtFormProps> = ({ onSubmit, onFormChange }) => {
  const [form] = Form.useForm();

  const onFinish = (values: FormValues) => {
    console.log('Form Values from GavtForm:', values);
    // if (!values.vowels || values.vowels.length === 0) {
    //   console.warn('No vowels selected');
    // }
    onSubmit(values);
  };

  return (
    <Form 
      form={form}
      onFinish={onFinish}
      onValuesChange={onFormChange}
      layout="vertical"
      initialValues={{
        vowels: [],
        gender: 'woman',
        shuffleOrder: false,
      }}
    >
      <Form.Item 
        name="vowels"
        label={ <span>
          Which vowel(s) would you like to practice?
          {/* <Tooltip title={
            <span>
            “ee” like in “seed”<br />
            “ih” like in “hid”<br />
            “eh” like in “head”<br />
            “ae” like in “sad”<br />
            “uh” like in “bud”<br />
            “oo” like in “good”<br />
            “ah” like in “god”<br />
            “aw” like in “lawn”<br />
            “ooh” like in “spoon”<br />
            </span>
          }
          >
            <InfoCircleOutlined style={{ marginLeft: 5}} />
          </Tooltip> */}
        </span>}
        rules={[{ required: true }]}
      >
        <Checkbox.Group options={vowels}/>
      </Form.Item>
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <svg width="610" height="20" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%" gradientUnits="userSpaceOnUse">
              <stop offset="0%" style={{ stopColor: '#9501F6'}} />
              <stop offset="100%" style={{ stopColor: '#2D0054'}} />
            </linearGradient>
          </defs>
          <line x1="10" y1="10" x2="600" y2="10" stroke="url(#grad1)" strokeWidth="5" />
          <polygon points="10,5 0,10 10,15" fill='#9501F6' />
          <polygon points="600,5 610,10 600,15" fill='#2D0054' />
        </svg>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          <span>brighter vowels</span>
          <span>darker vowels</span>
      </div>
      <br />

      <Form.Item name="gender" label="Are you trying to feminize or masculinize your voice?" rules={[{ required: true }]}>
        <Radio.Group>
          <Radio value="woman">Feminize</Radio>
          <Radio value="man" disabled>Masculinize</Radio>
          <Radio value="non-binary" disabled>
            Non-binary
            <Tooltip title="We are still working on developing resonant frequency reference data for non-binary speakers.">
              <InfoCircleOutlined style={{ marginLeft: 5 }} />
            </Tooltip>
          </Radio>
        </Radio.Group>
      </Form.Item>

      {/* <Form.Item name="region" label="Which dialect region would you like to use to set your reference targets? " rules={[{ required: true }]}>
        <Radio.Group options={regions.map(region => ({ label: region, value: region }))} />
      </Form.Item> */}

      <Form.Item name="shuffleOrder" valuePropName="checked">
        <Checkbox>Shuffle vowels' order?</Checkbox>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">Start Practicing</Button>
      </Form.Item>
    </Form>
  );
};

export default GavtForm;
