import { useNavigate } from 'react-router-dom';

import GavtForm from './GavtForm';
import ResonanceMenu from './ResonanceMenu';
import RefTable from './table/RefTable';
import CaliTable from './table/CaliTable';
import getRefFreq from './getRefFreq';

import { useState } from 'react';

import { Alert, Space, Modal, Button } from 'antd';

export interface FormValues {
  vowels: string[];
  gender: 'man' | 'woman';
  region: string;
  shuffleOrder: boolean;
}

export const vowelMapping: Record<string, string> = {
  'ee': '/i/',
  'ih': '/ɪ/',
  'eh': '/ɛ/',
  'ae': '/æ/',
  'ah': '/ɑ/',
  'aw': '/ɔ/',
  'uh': '/ʌ/',
  'ooh': '/u/',
  'oo': '/ʊ/'
};

const GavtSelection: React.FC = () => {
  const [refOpen, setRefOpen] = useState(false);
  const [caliOpen, setCaliOpen] = useState(false);
  const [formValues, setFormValues] = useState<FormValues | null>(null);
  const navigate = useNavigate();

  // useEffect(() => {
  //   console.log('Updated Form Values from GavtSelection:', formValues);
  // }, [formValues]);

  const handleFormSubmit = async (values: FormValues) => {
    try {
      setFormValues(values);

      const mappedVowels = values.vowels.map((vowel) => vowelMapping[vowel]);
      const frequencies = await getRefFreq(mappedVowels, values.gender, values.shuffleOrder);
      if (Object.keys(frequencies).length > 0) {
        // console.log(frequencies);
        navigate('/resonance/practice', { state: { frequencies } });
      } else {
        console.log('No resonance frequencies found.');
      }
    } catch (error) {
      console.error('Error fetching resonance frequencies:', error);
    }
  };

  const handleFormChange = (changedValues: Partial<FormValues>, allValues: FormValues) => {
    setFormValues(allValues);
  };

  return (
    // For real-time frequency display, wrap the GavtForm component in the FreqProvider component
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <h2>Resonance: Setup</h2>
      <Space direction="vertical" style={{ width: '50%', marginBottom: '20px'}}>
        {/* <Alert
          style={{ 
            backgroundColor: '#FFEFEF',
            borderColor: '#FFEFEF'
          }} 
          message="Setup"
          description={
            <>
              <div>
                Before you start to practice, it's highly recommended to use the <Link to="/resonance/calibrate"> calibration routine </Link> first. 
                You will get the <a onClick={()=>setCaliOpen(true)}>calibrated frequency values</a> for each vowel sound after the calibration.
                However, if you only wish to use the <a onClick={()=>setRefOpen(true)}>reference frequency</a> as a training target, 
                please select the vowel, gender, region, etc. directly below and start practicing immediately.
              </div>
            </>
          }
          type="info"
        /> */}
      </Space>
      <GavtForm onSubmit={handleFormSubmit} onFormChange={handleFormChange}/>
      <Modal
        title="Reference Frequency Values"
        centered
        open={refOpen}
        onOk={() => setRefOpen(false)}
        onCancel={() => setRefOpen(false)}
        width={1000}
        footer={[
          <Button key="submit" type="primary" onClick={() => setRefOpen(false)}>
            OK
          </Button>
        ]}
      >
        <RefTable />
      </Modal>
      <Modal
        title="Calibrated Frequency Values"
        centered
        open={caliOpen}
        onOk={() => setCaliOpen(false)}
        onCancel={() => setCaliOpen(false)}
        width={1000}
        footer={[
          <Button key="submit" type="primary" onClick={() => setCaliOpen(false)}>
            OK
          </Button>
        ]}
      >
        <CaliTable />
      </Modal>
      <ResonanceMenu formValues={formValues ?? undefined} navigate={navigate}/>
    </div>

  );
  };
  
  export default GavtSelection;