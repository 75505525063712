// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul, li {
  line-height: 1.8;
  padding-left: 20px;
}

ul ul {
  padding-left: 20px;
}

ul ul ul {
  padding-left: 20px;
}`, "",{"version":3,"sources":["webpack://./src/gavt/Gavt.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["ul, li {\n  line-height: 1.8;\n  padding-left: 20px;\n}\n\nul ul {\n  padding-left: 20px;\n}\n\nul ul ul {\n  padding-left: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
