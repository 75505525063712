import React from 'react';
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import getRefFreq from './getRefFreq';
import { vowelMapping } from './GavtSelection';

import { Modal, Button } from 'antd'

interface ResonanceMenuProps {
  formValues?: {
    vowels: string[];
    gender: 'man' | 'woman';
    shuffleOrder: boolean;
  };
  navigate?: (path: string, state?: any) => void;
}

const ResonanceMenu: React.FC<ResonanceMenuProps> = ({ formValues, navigate }) => {
  const location = useLocation();
  const routerNavigate = useNavigate();
  const [isNavigationVisible, setIsNavigationVisible] = useState(false);


  const menuItems = [
    { path: '/resonance', name: 'Tutorial' },
    { path: '/resonance/selection', name: 'Setup' },
    { path: '/resonance/practice', name: 'Practice' },  
  ];

  const activeNavigate = navigate || routerNavigate;

  const handlePracticeClick = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {

    if (location.pathname === '/resonance') {
      alert('You must select at least one vowel on Setup Page first');
      e.preventDefault();
      return;
    }

    if (location.pathname === '/resonance/selection') {
      if (formValues && formValues.vowels && formValues.vowels.length > 0) {
        const mappedVowels = formValues.vowels.map((vowel) => vowelMapping[vowel]);
        const frequencies = await getRefFreq(mappedVowels, formValues.gender, formValues.shuffleOrder);
        activeNavigate('/resonance/practice', { state: { frequencies } });
      } else {
        alert('You must select at least one vowel first');
        e.preventDefault();
      }

    }
  };

  const handleNavigationCancel = () => {
    setIsNavigationVisible(false);
  }

  const showNavigation = () => {
    setIsNavigationVisible(true);
  }

  const getNavigationContent = () =>{
    if (location.pathname === '/resonance/selection') {
      return `
          <ul>
                <li>Choose the vowel or vowels you want to practice.</li>
                <li>Remember, the target we set for R2 will differ depending on the vowel.</li>
                <li>Some vowels (like "ee" or "eh") are relatively easier to say with bright resonance, and some vowels (like "ah" or "oo") are more challenging.</li>
          </ul>
      `
    } else if (location.pathname === '/resonance/practice') {
      return `
          <ul>
              <li>Getting Started
                <ul>
                  <li>Here you will see the wave and the adjustable slider. For each vowel, the slider starts at a position based on data from research studies. For example, if you are targeting a brighter voice quality, the target will be placed at the lower edge of the range of variation from cis women producing that vowel.</li>
                  <li>Keep in mind that everyone’s voice is different, and the target we suggest as a starting point may not be a perfect fit for you. You should feel free to adjust the slider location to a value that feels comfortable for you. You can shift the target to the right for brighter resonance or to the left for darker resonance. If you find a target frequency that you like, you can save it as a custom target for that vowel by clicking “Save as custom value.”</li>
                  <li>To practice, draw out the vowel sound you selected (such as "eh") and watch how the wave responds. Try to identify the second peak in the wave, which is R2.
                    <ul>
                      <li>Your goal is to shift R2 closer to the target slider.</li>
                      <li>For tips on how to modify your R2, check out tutorial part 4, "Strategies for resonance."</li>
                      <li>Remember that changing the pitch of your voice is not the same as changing your resonance - see tutorial part 6 ("Pitch versus resonance") for more on this concept.</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>Next Steps
                <ul>
                  <li>Once you get a sense of how you can adjust your resonance, you can start practicing at the word level.
                    <ul>
                      <li>Even when you are producing words, you should still draw out the vowel so you can see if R2 is coming close to the slider.</li>
                      <li>During other speech sounds, your R2 will not be close to the slider - that's OK.</li>
                      <li>If you are having difficulty matching the target, you may want to go back to playing around with the vowel in isolation or in a single syllable.</li>
                    </ul>
                  </li>
                  <li>If you are feeling comfortable at the word level, you can click "More complex" to try a longer utterance.
                    <ul>
                      <li>You can practice the same vowel in chains of 2 to 5 syllables.</li>
                      <li>To begin, you will probably want to chant the chains and draw out the vowel in each word.</li>
                      <li>Then try producing the same chain with a more natural intonation instead of a chant. You can still look for R2 to come close to the slider during the target vowel in each word.</li>
                    </ul>
                  </li>
                  <li>Then try another vowel, or try mixing it up with different vowels. Don't forget that the tutorial videos are there for models and strategies!</li>
                </ul>
              </li>
          </ul>
      `
    } else {
      return `
        <ul>
          <li>Setup Page
            <ul>
              <li>Choose the vowel or vowels you want to practice.</li>
              <li>Remember, the target we set for R2 will differ depending on the vowel.</li>
              <li>Some vowels (like "ee" or "eh") are relatively easier to say with bright resonance, and some vowels (like "ah" or "oo") are more challenging.</li>
            </ul>
          </li>
          <li>Practice Page
            <li>Getting Started
              <ul>
                <li>Here you will see the wave and the adjustable slider. For each vowel, the slider starts at a position based on data from research studies. For example, if you are targeting a brighter voice quality, the target will be placed at the lower edge of the range of variation from cis women producing that vowel.</li>
                <li>Keep in mind that everyone’s voice is different, and the target we suggest as a starting point may not be a perfect fit for you. You should feel free to adjust the slider location to a value that feels comfortable for you. You can shift the target to the right for brighter resonance or to the left for darker resonance. If you find a target frequency that you like, you can save it as a custom target for that vowel by clicking “Save as custom value.”</li>
                <li>To practice, draw out the vowel sound you selected (such as "eh") and watch how the wave responds. Try to identify the second peak in the wave, which is R2.
                  <ul>
                    <li>Your goal is to shift R2 closer to the target slider.</li>
                    <li>For tips on how to modify your R2, check out tutorial part 4, "Strategies for resonance."</li>
                    <li>Remember that changing the pitch of your voice is not the same as changing your resonance - see tutorial part 6 ("Pitch versus resonance") for more on this concept.</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>Next Steps
              <ul>
                <li>Once you get a sense of how you can adjust your resonance, you can start practicing at the word level.
                  <ul>
                    <li>Even when you are producing words, you should still draw out the vowel so you can see if R2 is coming close to the slider.</li>
                    <li>During other speech sounds, your R2 will not be close to the slider - that's OK.</li>
                    <li>If you are having difficulty matching the target, you may want to go back to playing around with the vowel in isolation or in a single syllable.</li>
                  </ul>
                </li>
                <li>If you are feeling comfortable at the word level, you can click "More complex" to try a longer utterance.
                  <ul>
                    <li>You can practice the same vowel in chains of 2 to 5 syllables.</li>
                    <li>To begin, you will probably want to chant the chains and draw out the vowel in each word.</li>
                    <li>Then try producing the same chain with a more natural intonation instead of a chant. You can still look for R2 to come close to the slider during the target vowel in each word.</li>
                  </ul>
                </li>
                <li>Then try another vowel, or try mixing it up with different vowels. Don't forget that the tutorial videos are there for models and strategies!</li>
              </ul>
            </li>
          </li>
        </ul>
      `
    }
  }

  return (
    <>
      <nav className="nav">
        {menuItems.map((item, index) => (
          <React.Fragment key={item.name}>
            <Link 
              to={item.path} 
              className={`link ${location.pathname === item.path ? 'active' : ''}`}
              onClick={item.path === '/resonance/practice' ? handlePracticeClick : undefined}
            >
              {item.name}
            </Link>
            {index < menuItems.length - 1 && <span className="separator">|</span>}
          </React.Fragment>
        ))}
      </nav>
      <div style={{ textAlign: 'center', marginTop: '5px' }}>
        <Button onClick={showNavigation}>
          Help navigating the app
        </Button>
      </div>
      <Modal
        title="Help navigating the app"
        open={isNavigationVisible}
        onCancel={handleNavigationCancel}
        footer={[
          <Button key="close" onClick={handleNavigationCancel}>
            Close
          </Button>,
        ]}
        width={800} 
      >
        <div dangerouslySetInnerHTML={{ __html: getNavigationContent() }} />
      </Modal>
    </>
  );
}

export default ResonanceMenu;