const transcripts = {
  part1: 
    `<p>When people think about voice and gender, they usually think of the pitch of the voice. However, the perception of gender is also influenced by a number of other factors. Here, we will be focusing on resonance.</p>
    
    <p>Resonance and pitch are not the same thing. The pitch of your voice depends on how fast your vocal cords are vibrating, while resonance is influenced by the length and shape of your vocal tract above the level of the vocal cords.</p>
    
    <p>Resonance can be challenging to understand, so our software provides a visual display of resonance to help make it more concrete. We will be using a wave that responds to resonance in your speech in real time. As I'm talking into my microphone, you can see how the wave is moving around as my speech changes: "ee, ooh, ah."</p>
    
    <p>Each peak represents one element of your vocal resonance. Some studies have shown that the second peak starting from the left is especially important for gender perception, so we will focus on that peak. We will call the second resonant peak R2.</p>
    
    <p>We will use the triangle slider to set a target for R2. You may see white lines appear in the wave. The white lines are there to help you find the peaks, but sometimes you will see a peak without a line or a line without a peak. When this happens, you should focus on the peaks that form the shape of the wave—they're more important than the white lines.</p>`,
  
  part2:
    `<p>The location of resonant peaks differs between more feminine-perceived voices, which we will be calling "brighter voices," and more masculine-perceived voices, which we will be calling "darker voices." On average, peaks are further to the left in darker voices and further to the right in brighter voices.</p>
    
    <p>However, the location of the peaks will always depend on the sound that is being produced, such as "ah" versus "ee" vowels, and the target peak location that we set for one vowel will not apply to other vowels.</p>
    
    <p>Here are the peaks for an "ee" sound. The second peak, which we're calling R2, is pretty far to the right. Now I'm going to switch to an "ah" sound without changing anything else in my voice. The R2 peak is much further to the left for "ah" than for "ee."</p>
    
    <p>Now let's look at how the peaks could differ when the same vowel, such as "ah," is produced with brighter and darker resonance. The second peak is slightly further to the right for the brighter or more feminine voice.</p>
    
    <p>One thing to keep in mind is that the differences in peak location between brighter and darker voices are subtle compared to the differences between vowels. Our software will encourage you to focus on one vowel at a time as you practice resonance.</p>`,
  
  part3: 
    `<p>It's possible to change the location of your resonant peaks by moving your lips, mouth, and tongue to change the shape of the vocal tract. In our software, we will use an adjustable slider to provide a target location for R2. We'll be focusing on one vowel at a time.</p>
    
    <p>Here, we'll practice with the "uh" vowel. I'm going to start with the slider set to an R2 that's neutral for me. We'll talk about setting these targets in another video. When I produce an "uh" sound with my neutral resonance, you should see the second peak of the wave align with that target.</p>
    
    <p>Now, I'll move the slider to a slightly brighter resonance and change my resonance to try to match that target."</p>
    
    <p>I can also shift in the other direction and produce the "a" vowel with a slightly darker resonance.</p>
    
    <p>Note that I'm not changing my vocal pitch—I'm changing the shape of my vocal tract. If you find that hard to believe, watch the video "Pitch vs. Resonance" for more information. The next video will give you strategies for changing your resonance.</p>`,
  
  part4:  
    `<p>Let's look at a few strategies you can use to modify your resonance. Remember that we will be focusing on the position of the second peak, R2. In general, R2 is darker when the lips are rounded and brighter when the lips are pulled back.</p>
      
    <p>I will start with my neutral "uh" and then round my lips to darken R2. Then I'll start with my neutral "uh" and retract my lips in a smile to brighten R2.</p>
    
    <p>In addition, R2 is generally darker when your tongue is further back in your mouth and brighter when your tongue is further forward in your mouth. I will start with my neutral "uh" and then move my tongue back to darken R2. Then I'll start with my neutral "uh" and move my tongue forward to brighten R2.</p>
    
    <p>Lastly, R2 is darker when you open up a large space in the back of your throat, like a yawn, and brighter when you narrow your throat by drawing the back wall forward. I will start with my neutral "uh" and then open my throat like a yawn to darken R2. Then I will start with my neutral "uh" and narrow the space in the back of my throat to brighten R2.</p>
    
    <p>We know it can be hard to tell if you have your tongue and throat positioned correctly just by feeling. We encourage you to play around with your voice and focus on the R2 peak to know if you're moving in the right direction. You can start by practicing these strategies one at a time. As you get more comfortable, try putting the strategies together to create a larger change in resonance.</p>`,
  part5:
    `<p>Once you get comfortable changing your resonance, you can practice using these strategies while saying different words and phrases. Because different vowels have different resonant peaks, we will focus on one vowel at a time.</p>

    <p>To make practice more challenging, you can practice matching a target for R2 in longer phrases. You should try to make the R2 peak come close to the target line every time you say the target vowel, such as the "uh" sound. But it's okay if the peaks look different when you're saying sounds other than the target vowel.</p>
    
    <p>Here's a demonstration. I'll target the vowel "uh" with an R2 that's moderately brighter than my neutral resonance. </p>
    
    <p>First, I'm going to sustain just an isolated vowel and try to slide my resonance up to the target: "Uh." </p>

    <p>Now, I'm going to transition from that isolated vowel into a one-syllable word like "some": "Uh, some."</p>
    
    <p>Next, I'll try a two-syllable word like "someone": "Uh, someone."</p>
    
    <p>Then, I'll try adding a word onto that, like "was": "Uh, someone was."</p>
    
    <p>And now, I can add an additional word: "Uh, someone was up."</p>
    
    <p>Now, I'll try that phrase again, but instead of a chant, I'll use a more natural intonation while maintaining that bright resonance: "Someone was up."</p>
    
    <p>If you find it challenging to hit your R2 target in longer phrases, you're not alone. Take as long as you need to practice the vowel in isolation until you feel comfortable. You can use the "Strategies for Resonance" video to review tips for changing your R2.</p>`,
  
  part6:
    `<p>When we think about how gender is perceived in voice, we often think exclusively about pitch. However, there are a number of different voice features that factor into how gender is perceived, and resonance is one feature that can play a big role in gender perception.</p>
        
    <p>It can be difficult to differentiate between resonance and pitch, so this video is intended to help demonstrate that difference. The left side of the screen is measuring pitch, and the right side of the screen is measuring resonance. I'm going to demonstrate the difference between brightening resonance versus increasing pitch, and these tools can help us visualize that difference.</p>
    
    <p>The vowel that I'm looking at right now is "uh." If I hold out that sound in my natural voice, you're going to see two peaks in the wave that are to the left of the target line. Those peaks show up to the left of the target line because my resonance is naturally darker than what I'm aiming for if I'm trying to feminize my voice.</p>
    
    <p>On the left side of the screen, you should see a steady line that represents the pitch of my voice, which, in this case, should stay pretty much the same. So, I'm going to start off by showing where my baseline voice is.</p>

    <p>As expected, you can see those two peaks showing up to the left of the target line, while the pitch line remains steady. This tells me that my resonance is dark, and my pitch isn't changing.</p>
    
    <p>If I want to feminize my voice, I need to brighten it. However, what a lot of people do is just start increasing their pitch. I'm going to demonstrate increasing my pitch and show you that it doesn't move those peaks to or beyond that target line because I'm not changing my resonance.</p>
    
    <p>If you watch the line on the left side of the screen, my pitch is obviously going up, but the peaks on the right side of the screen are not moving over. So, what if I try moving my voice out of my chest and try to feel it coming more from my mouth? Let's see what happens with those peaks.</p>

    <p>This time, if you watch the line on the left side of the screen, it remains steady, which means my pitch was not changing. But on the right side of the screen, you can see those peaks have moved to the right, toward the target line.</p>
    
    <p>This is a tool that can help you recognize whether or not you're increasing your pitch or brightening your resonance. For more information on how to shift resonance without shifting pitch, watch the Module 4 video on strategies for shaping resonance.</p>`,
};

export default transcripts;