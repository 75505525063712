import React from 'react';
import { Typography, Button, Row, Col, Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const { Title, Paragraph } = Typography;

interface MainProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: () => void;
  handleCancel: () => void;
  goToSample: () => void;
}

const Main: React.FC<MainProps> = ({ isModalOpen, setIsModalOpen, showModal, handleCancel }) => {
  const navigate = useNavigate();

  const goToSample = () => {
    navigate('/pitch');
  };

  return (
    <div style={{ width: '100%', minHeight: '100vh' }}>
      {/* Introduction Section */}
      <div style={{
        width: '100%',
        height: '5rem', // Fixed height in pixels
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '0' // Ensure no margin at the bottom
      }}>
        <div>
          <Typography>
            <Title level={3} style={{ fontSize: '2.6vw', color: 'rgb(36, 36, 131)', maxWidth: '10rem', margin: "0 auto", textAlign: 'center', fontWeight: 'normal' }}>
              Welcome to
            </Title>

            <Title level={3} style={{ fontSize: '2.6vw', color: 'rgb(36, 36, 131)', maxWidth: '400px', margin: "20px auto 0", textAlign: 'center', fontWeight: 'normal', marginTop: "-5px" }}>
              our transgender voice coaching app!
            </Title>

            <Paragraph style={{ fontSize: '1.5vw', color: 'rgb(36, 36, 131)', maxWidth: '500px', margin: "20px auto", textAlign: 'center' }}>
              The exercises and resources within this app aim to help transfeminine individuals practice changing their voice in different ways. Right now, the app mainly focuses on pitch exercises, but is being expanded to provide other aspects to help achieve desired voice results.
            </Paragraph>
            <Button disabled={true} onClick={goToSample} type="primary" style={{ border: '1.5px solid rgb(41, 41, 130)', borderRadius: '30px', fontSize: '17px', background: 'linear-gradient(to left, #5A82E1, #2f2a5a)', WebkitBackgroundClip: 'text', backgroundClip: 'text', color: 'transparent', width: '180px', textAlign: 'center', margin: '0 auto', paddingTop: '2px', paddingBottom: '5px', marginTop: '7px', letterSpacing: '4px', fontWeight: 'bold' }}>
              TUTORIAL
            </Button>
          </Typography>
        </div>
      </div>

      {/* Bottom Section */}
      <div style={{ padding: '20px', backgroundColor: 'white', marginTop: '10px' }}>
        <div style={{ textAlign: 'center', marginBottom: '35px' }}>
          <Title level={2} style={{ textDecoration: 'underline', textDecorationSkipInk: 'auto', textUnderlineOffset: '10px', color: '#8778AA' }}>EXERCISES</Title>
          <Paragraph style={{ fontSize: '20px', maxWidth: '750px', margin: '0 auto', color: '#6E6E73', lineHeight: '40px' }}>
            Working through these exercises for about two 15-minute periods per day should help you obtain a desired voice. Feel free to select whatever exercises might be beneficial, and use the Assessment function whenever you want to check your progress.
          </Paragraph>
        </div>

        <Row justify="center" gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={6}>
            {/* <Link to='/pitch'> */}
              <Card
                // actions={[
                //   <Link to="/pitch">MORE INFO</Link>
                // ]}
                // hoverable
                bordered={false}
                style={{ textAlign: 'center', height: '100%', border: '1px solid #8DAADA', padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
              >
                <div>
                  <Title level={1} style={{ color: '#8DAADA', fontSize: "0.5rem", paddingTop: '0', marginTop: '0', marginBottom: '0' }}>01</Title>
                  <Title level={3} style={{ color: '#8DAADA', marginTop: '0' }}>PITCH TRAINING</Title>
                  <Paragraph
                    style={{
                      fontSize: '1.2vw',
                      color: '#6E6E73',
                      margin: '2.7vw 2vw 1vw 2vw',
                    }}
                  >
                    Pitch is one of the biggest contributors to the way a voice is perceived. In these exercises, you can practice matching different pitch patterns.
                  </Paragraph>
                </div>
              </Card>
            {/* </Link> */}
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            {/* <Link to="/volume"> */}
              <Card
                // actions={[
                //   <Link to="/volume">MORE INFO</Link>
                // ]}
                // hoverable
                bordered={false}
                style={{ textAlign: 'center', height: '100%', border: '1px solid #436AC2', padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
              >
                <div>
                  <Title level={1} style={{ color: '#436AC2', fontSize: "0.5rem", paddingTop: '0', marginTop: '0', marginBottom: '0' }}>02</Title>
                  <Title level={3} style={{ color: '#436AC2', marginTop: '0' }}>PITCH & VOLUME TRAINING</Title>
                  <Paragraph
                    style={{
                      fontSize: '1.2vw',
                      color: '#6E6E73',
                      margin: '1vw 2vw 1vw 2vw',
                    }}
                  >
                    Trans people who can speak at a desired pitch often find that they sound quieter than they would like. In these exercises, you can therefore practice matching different pitches and simultaneously sounding loud enough.
                  </Paragraph>
                </div>
              </Card>
            {/* </Link> */}
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            {/* <Link to="/assessment"> */}
              <Card
                // actions={[
                //   <Link to="/assessment">MORE INFO</Link>
                // ]}
                // hoverable
                bordered={false}
                style={{ textAlign: 'center', height: '100%', border: '1px solid purple', padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
              >
                <div>
                  <Title level={1} style={{ color: '#8376AA', fontSize: "0.5rem", paddingTop: '0', marginTop: '0', marginBottom: '0' }}>03</Title>
                  <Title level={3} style={{ color: '#8376AA', marginTop: '0' }}>ASSESSMENT</Title>
                  <Paragraph
                    style={{
                      fontSize: '1.2vw',
                      color: '#6E6E73',
                      margin: '3.2vw 2vw 1vw 2vw',
                    }}
                  >
                    Not sure how your voice sounds right now? Go through a few quick steps to find out.
                  </Paragraph>
                </div>
              </Card>
            {/* </Link> */}

          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Main;
