import { Tabs, Row, Col, Card } from 'antd';

import CustomWave from './CustomWave'
import TutorialGraphDisplay from './TutorialGraphDisplay'
import Sample from '../../Route/Sample'
import { useOptionsHooks } from '../../hooks/useOptionsHooks';

const onChange = (key) => {
  console.log(key);
};

const GavtTutorial = () => {

  const {
    gender,
    setGender,
    genderName,
    audioPlayerNew,
    audioKey,
    audioSrc,
    fetchAudioData,
    handleIconClick,
    itemsAvatar,
  } = useOptionsHooks();

  return (
      <Tabs
        tabPosition='left'
        onChange={onChange}
        type="card"
        items={[
          {
            label: 'Blank Slide with Wave',
            key: '1',
            children: <CustomWave />,
          },
          {
            label: 'Vowel toggle',
            key: '2',
            children: <TutorialGraphDisplay currentStep='7'/> //p3s1
          },
          {
            label: 'Brighter/Darker toggle',
            key: '3',
            children: <TutorialGraphDisplay currentStep='10'/>, //p4s2
          },
          {
            label: 'Side-by-side pitch and resonance',
            key: '4',
            children: 
            <Row gutter={24} style={{ padding: '20px' }}>
              <Col span={12}>
                <Card title="Pitch" bordered={true} style={{ height: '1000px' }}>
                  <Sample
                    gender={gender}
                    setGender={setGender}
                    genderName={genderName}
                    audioKey={audioKey}
                    audioSrc={audioSrc}
                    fetchAudioData={fetchAudioData}
                    handleIconClick={handleIconClick}
                    itemsAvatar={itemsAvatar}
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card 
                  title="Resonance"
                  bordered={true}
                  style={{ height: '1000px'}}
                  styles={{ 
                    body: { 
                      padding: 0,                      
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}}
                >
                    <CustomWave canvasWidth = '80%'/>
                </Card>
              </Col>
            </Row>
          },
        ]}
        style={{ marginTop: '50px', width: '100%', height: '100%' }}
      />
  )
}

export default GavtTutorial;